.ui.form .slider .value {
  text-align: center;
  padding-top: 0px;
  font-weight: 500;
  font-size: 20px;
}

.ui.form .slider .value.left, .ui.form .slider .value.right {
  padding-top: 13px;
}

.ui.form .slider.flex-row .value, .ui.form .slider.flex-row .valueInput:not(.flex-grow) {
  flex-basis: 80px;
}

.ui.form .slider .valueInput {
  text-align: center;
}

.ui.form .slider .valueInput:not(.flex-grow) input {
  width: 70px;
}

.ui.form .slider .stepper-buttons {
  padding-left: 15px;
  padding-right: 15px;
  white-space: nowrap;
}

/* Range slider - custom rewritten style */
.rangeslider {
  margin: 20px 0;
  position: relative;
  -ms-touch-action: none;
  touch-action: none
}

.rangeslider, .rangeslider .rangeslider__fill {
  display: block;
  background-color: rgba(0, 0, 0, .1);
}

.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  /*box-shadow: 0 1px 3px rgba(0, 0, 0, .4), 0 -1px 3px rgba(0, 0, 0, .4)*/
}

.rangeslider .rangeslider__handle:hover .rangeslider__tooltip {
  opacity: 1
}

.rangeslider .rangeslider__tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, .8);
  font-weight: 400;
  font-size: 14px;
  transition: all .1s ease-in;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  opacity: 0
}

.rangeslider .rangeslider__tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%
}

.rangeslider .rangeslider__tooltip:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0
}

.rangeslider .rangeslider__label-list .rangeslider__label {
  margin-top: 10px;
  font-weight: bold;
}

.rangeslider-horizontal {
  height: 8px;
  border-radius: 3px
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  border-radius: 3px;
  top: 0
}

.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: -10px
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: " ";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: #444444;
  border: 1px solid #444444;
  /*box-shadow: inset 0 1px 3px rgba(0, 0, 0, .4), inset 0 -1px 3px rgba(0, 0, 0, .4)*/
}

.rangeslider-horizontal .rangeslider__tooltip {
  top: -55px
}

.rangeslider-horizontal .rangeslider__tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, .8);
  left: 12px;
  bottom: -8px
}

.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent
}

.rangeslider-vertical .rangeslider__fill, .rangeslider-vertical .rangeslider__handle {
  position: absolute
}

.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0
}

.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none
}

.rangeslider-vertical .rangeslider__tooltip {
  left: -55px;
  top: -15px
}

.rangeslider-vertical .rangeslider__tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, .8);
  left: 100%;
  top: 12px
}

.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0
}

.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit
}

.rangeslider-vertical .rangeslider__label-list {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px
}

.rangeslider-vertical .rangeslider__label-list .rangeslider__label {
  position: absolute
}

.rangeslider-vertical .rangeslider__label-list .rangeslider__label:before {
  content: "";
  width: 10px;
  height: 2px;
  background: #000;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1
}

.rangeslider__label-list .rangeslider__label {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px
}