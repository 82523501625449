@font-face {
  font-family: 'Icons';
  src: url("../../lib/semantic-ui/2.2.13/themes/default/assets/fonts/icons.eot");
  src: url("../../lib/semantic-ui/2.2.13/themes/default/assets/fonts/icons.eot?#iefix") format('embedded-opentype'), url("../../lib/semantic-ui/2.2.13/themes/default/assets/fonts/icons.woff2") format('woff2'), url("../../lib/semantic-ui/2.2.13/themes/default/assets/fonts/icons.woff") format('woff'), url("../../lib/semantic-ui/2.2.13/themes/default/assets/fonts/icons.ttf") format('truetype'), url("../../lib/semantic-ui/2.2.13/themes/default/assets/fonts/icons.svg#icons") format('svg');
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}

#content {
  /*padding: 20px;*/
}

body {
  margin: 0px;
  overflow: hidden;
}


/* Source editor */

.component.source-editor textarea{
  resize: none;
  margin: 20px;
}

/* Application */
.application {
  width: 100vw;
  height: 100vh;
}

.body .search .ui.input.path {
  width: 800px;
}

/* Flow */
.component.detail.flow {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

/* Path console */
.component.detail.console {
  padding: 20px;
}
.component.detail.console .inner {
  padding-left: 10px;
  border-left: 1px solid #c4d8ff;
  margin-bottom: 20px;
}

/* Inspector */
.component.detail.medium {
  flex-basis: 30%;
}

/* Browser */
.component.browser {
  min-width: 250px;
  max-width: 250px;
}


@media all and (max-width: 640px) {
  .meta.row, .meta.menu  {
      flex-direction: column;
  }
  .component.view {
    height: 100vh;
  }
  .component.browser {
    min-width: inherit;
    max-width: inherit;
  }
  body {
    overflow-y: scroll;
  }
}
