.log-console .clear-icon {
  position: absolute;
  top: 80px;
  right: 47px;
  cursor: pointer;
}

.log-console .letterCircle {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  padding: 0px;
  display: inline-block;
  font-size: 11px;
  text-align: center;
}

.log-console .actionColor {
  color: #00CED1;
}

.log-console .actionColor.frontend {
  color: #0000CD;
}

.log-console .actionColor.composite {
  color: #FF1493;
}

.log-console .actionColor.integration, .log-console .actionColor.framework  {
  color: #FF7F50;
}

.log-console .actionColor.server-call {
  color: #DAA520;
}

.log-console .kindColor {
  color: darkseagreen;
}

.log-console .server-log-a {
  font-family: Menlo, monospace;
  font-size:11px;
  line-height:14px;
}

.log-console .left-arrow {
  color: #6e6e6e;
  font-size: 10px;
  margin-right: 3px;
  white-space: pre;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}