.exceptionEnd {
  background-color: #FFBFBF;
  padding: 20px;
  margin: auto;
  margin-top: 10px;
  border: 1px solid red;
  color: red;
}

.outputEnd {
  background-color: #E0FCE4;
  padding: 20px;
  margin: auto;
  margin-top: 10px;
  border: 1px solid #416646;
}

.curpointer {
  cursor: pointer;
}

.ui.form .field label.warning, .ui.form .field label.error, .ui.form .field label.help {
  min-height: 22px;
}

.ui.form .field label.error {
  color: red;
  font-weight: normal;
  margin: 0px;
}

.ui.form .field.warning .input, .ui.form .field.warning label {
  color: #B77B14;
}

.ui.form .field label.warning {
  color: orange;
  font-weight: normal;
  margin: 0px;
  vertical-align: top;
}

.ui.form .field label.warning .ui.checkbox label:before {
  margin: 3px;
  width: 14px;
  height: 14px;
}

.ui.form .field label.warning span.text {
  position: relative;
  top: -2px;
  margin-left: 3px;
}

.ui.form .field label.help {
  color: #BCBCBC;
  font-weight: normal;
  margin: 0px;
}

.ui.form .field.inline label.editable {
  margin-right: 0.857143em;
}

.ui.form .field.inline label.help {
  margin-left: 0.857143em;
}

.ui.form .field input.error, .ui.form .field select.error, .ui.form .field select.textarea {
  border-color: red;
}

.ui.form .field.warning input:not([type]), .ui.form .field.warning input[type=text], .ui.form .field.warning textarea {
  background: #FFF9F2;
  border-color: #F7D8AA;
  color: #B77B14;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.form .upload-file-name {
  padding-left: 10px;
}


.ui.row.mc-label {
  padding-left: 14px;
}

span.rstar {
  color: red;
  font-weight: bold;
}

div.space20 {
  height: 20px;
}

.flowloader {
  min-height: 150px;
}

.ui.sortable.table thead th.sortable:after {
  content: '\f0dc';
}

.ui.sortable.table thead th.sorted.ascending:after, .ui.sortable.table thead th.sorted.descending:after {
  color: red;
}

.ui.sortable.table thead th {
  white-space: normal;
}

.hbox .gapped:not(:first-child) {
  margin-left: 10px;
}

.vbox .gapped:not(:first-child) {
  margin-top: 10px;
}

.hbox button + label.help {
  display: none;
}

.hbox .ui.button {
  margin-right: 0px;
}

.ripper-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-right: 0.25em;
}

.ripper {
  position: absolute;
  width: 0px;
  height: 1px;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  background-color: rgba(236, 240, 241, .3);
  cursor: pointer;
}

.ripper-wrapper .ui.button {
  margin-right: 0px;
}

.ui.grid {
  margin-top: -3px;
  margin-bottom: -3px;
}

.ui.grid>.row {
  padding-top: 3px;
  padding-bottom: 3px;
}

@media only screen and (max-width: 767px) {
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.grid > .stackable.stackable.row > .column {
    padding: 3px 1rem !important;
  }
}

.ui.segment {
  padding-top: 13px;
  padding-bottom: 13px;
}

.ui.checkbox label.error:before, .ui.checkbox label.help:before {
  display: none;
}


.ui.checkbox input~label.error:after, .ui.checkbox input~label.help:after {
  display: none;
}

.mnc.tabpanel .ui.segment:last-child {
  margin-bottom: 1rem;
}

.mnc.error, .mnc.red, .ui.form .mnc.red, .ui.form .mnc.error {
  color: red;
}

.mnc.green, .ui.form .mnc.green {
  color: green;
}

.mnc.blue, .ui.form .mnc.blue  {
  color: blue;
}

.mnc.clickable.icon:not(.disabled) {
  cursor: pointer;
}

.ui.form .flex-row {
  display: flex;
  flex-flow: row wrap;
}

.ui.form .flex-column {
  display: flex;
  flex-flow: column wrap;
}

.ui.form .flex-grow {
  flex-grow: 1;
}

.ui.form .flex-center {
  justify-content: center;
}

.ui.form .flex-right {
  justify-content: flex-end;
}

.ui.form .flex-no-wrap {
  flex-wrap: nowrap;
}

.ui.form .field.wide.inline > div, .ui.form .field.wide.inline > div.ui.input {
  display: inline-block;
  width: auto;
}

.mnc-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  padding: 50px;
  z-index: 2000;
  overflow: auto;
}

@media only screen and (max-width: 575px) {
  .mnc-backdrop {
    padding: 5px;
  }
}

.mnc-modal {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 auto;
  padding: 30px;
  z-index: 2001;
  font-size: 1rem;
}

.showing-modal {
  overflow: hidden;
}

.mnc-modal .close.icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.mnc-modal > .header {
  display: block;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 1.4em;
  background: #FFFFFF;
  margin: 0em;
  padding: 0 1.5rem 1rem 1.5rem;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.mnc-modal > .content {
  display: block;
  width: 100%;
  font-size: 1em;
  line-height: 1.4;
  padding: 1.5rem;
  background: #FFFFFF;
}

.mnc-modal .actions {
  text-align: right;
}

/* Modal responsive */
@media only screen and (max-width: 767px) {
  .mnc-modal {
    width: 95%;
  }
  .mnc-modal.mini {
    width: 95%;
  }
  .mnc-modal.tiny {
    width: 95%;
  }
  .mnc-modal.small {
    width: 95%;
  }
  .mnc-modal.large {
    width: 95%;
  }
}

@media only screen and (min-width: 768px) {
  .mnc-modal {
    width: 88%;
  }
  .mnc-modal.mini {
    width: 35.2%;
  }
  .mnc-modal.tiny {
    width: 52.8%;
  }
  .mnc-modal.small {
    width: 70.4%;
  }
  .mnc-modal.large {
    width: 88%;
  }
}

@media only screen and (min-width: 992px) {
  .mnc-modal {
    width: 850px;
  }
  .mnc-modal.mini  {
    width: 340px;
  }
  .mnc-modal.tiny {
    width: 510px;
  }
  .mnc-modal.small {
    width: 680px;
  }
  .mnc-modal.large {
    width: 1020px;
  }
}

@media only screen and (min-width: 1200px) {
  .mnc-modal {
    width: 900px;
  }
  .mnc-modal.mini  {
    width: 360px;
  }
  .mnc-modal.tiny {
    width: 540px;
  }
  .mnc-modal.small {
    width: 720px;
  }
  .mnc-modal.large {
    width: 1080px;
  }
}

@media only screen and (min-width: 1920px) {
  .mnc-modal {
    width: 950px;
  }
  .mnc-modal.mini  {
    width: 380px;
  }
  .mnc-modal.tiny {
    width: 570px;
  }
  .mnc-modal.small {
    width: 760px;
  }
  .mnc-modal.large {
    width: 1140px;
  }
}

video.mnc.video {
  width: 100% !important;
  height: auto !important;
}

video.mnc.video.mirrored {
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateY(180deg); /* Firefox */
}

/* whisperbox */
.whisperbox .input-show-dropdown {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.whisperbox .search-dropdown {
  position: absolute;
  width: calc(100% - 28px);
  z-index: 2;
}

.whisperbox .dropdown-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #f1f1f1;
  border-top: 0;
  border-radius: 3px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.whisperbox .dropdown-ul-li {
  padding: 10px;
  font-size: 16px;
  font-weight: 100;
  background-color: white;
}

.whisperbox .dropdown-ul-li:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
}

.whisperbox .dropdown-ul-li.active {
  background-color: #f1f1f1;
  cursor: pointer;
}

.rows-per-page .ui.compact.dropdown {
  padding: 0.2em 0.3em;
  padding-right: 30px;
  line-height: 1.9em;
  min-height: 20px;
}

.rows-per-page .ui.compact.dropdown .dropdown.icon {
  padding: 5px;
  padding-top: 10px;
}