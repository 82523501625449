.component.flow {
  color: #000000;
}

.component.flow .mc-label .ui.header  {
  font-size: 20px;
  color: #161360;
  letter-spacing: 0.83px;
  font-weight: normal;
}

.component.flow label.editable {
  font-size: 16px;
  color: #4A4A4A;
  letter-spacing: 0;
  font-weight: normal;
}

.component.flow .new-button button {
  background: #30CB81;
  border-radius: 2px;
  border: none;
  color: #FFFFFF;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: normal;
  padding-left: 30px;
  padding-right: 30px;
}

.component.flow .new-button button:hover {
  background: #289c63;
}

.component.flow button.major {
  background: #30CB81;
  border-radius: 2px;
  border: none;
  color: #FFFFFF;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: normal;
}

.component.flow button.major:hover {
  background: #289c63;
}

.component.flow h3.ui.title, .component.flow .accordion:not(.styled) .title, .component.flow .accordion:not(.styled) .title .ui.header {
  font-size: 20px!important;
  color: #161360!important;
  letter-spacing: 0.83px;
  font-weight: normal;
}

.component.flow input:not([type='file']) {
  border-radius: 2px;
  border: 1px solid #9B9B9B;
  font-size: 14px;
  color: #516C85;
  letter-spacing: 0;
}

.component.flow .ui.dropdown, .component.flow .ui.dropdown.active {
  border-radius: 2px;
  border: 1px solid #9B9B9B;
  font-size: 14px;
  color: #516C85;
  letter-spacing: 0;
}

.component.flow .ui.dropdown .menu.visible {
  border-radius: 2px;
  border: 1px solid #9B9B9B;
}

.component.flow table.ui.table {
  border: 0px;
  border-radius: 0px;
}

.component.flow table.ui.table th {
  font-size: 12px;
  color: rgba(81,108,133,0.6);
  letter-spacing: 0;
  font-weight: normal;
  background-color: transparent;
}

.component.flow table.ui.table td {
  font-size: 12px;
  color: #000000;
  letter-spacing: 0;
}

.component.flow .ui.striped.table tbody tr:nth-child(2n), .component.flow .ui.striped.table>tr:nth-child(2n) {
  background: rgba(227,237,237,0.26);
}

.component.flow .ui.table td, .component.flow .ui.table th {
  border-right: rgba(151,151,151,0.07) 1px solid;
  border-left: 0;
  border-top: rgb(227,237,237) 1px solid;
  border-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.component.flow .ui.table tr>th:first-child, .component.flow .ui.table tr>td:first-child {
  border-left: rgba(151,151,151,0.07) 1px solid;
}

.component.flow .ui.table thead tr:first-child>th:last-child, .component.flow .ui.table thead tr:first-child>th:first-child,
.component.flow .ui.table thead tr:last-child>td:last-child, .component.flow .ui.table thead tr:last-child>td:first-child{
  border-radius: 0 0 0 0;
}

.component.flow .ui.table thead tr:last-child>td {
  border-bottom: rgb(227,237,237) 1px solid;
}

.component.flow .ui.table td button {
  background-color: transparent;
  border: 0px;
  padding: 0;
  padding-left: 7px;
  color: #38A0FA;
}

.component.flow .ui.table td button:hover {
  color: #275e9d;
}

.component.flow .pagination.menu {
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.component.flow .pagination.menu .item {
  border: 0;
  box-shadow: none;
  padding-left: 5px;
  padding-right: 5px;
  color: #516C85;
}

.component.flow .pagination.menu .icon.item {
  color: #3A3878;
}

.component.flow .pagination.menu .item:hover {
  background-color: transparent;
  color: #4A90E2;
}

.component.flow .pagination.menu .item.disabled:hover {
  color: #516C85;
}

.component.flow .pagination.ui.menu .item:before {
  background: none;
}

.component.flow .pagination.menu .active.item {
  background: transparent;
}

.component.flow .pagination.menu .active.item input {
  color: #4A90E2;
}

.component.flow .pagination.menu .item i.icon.arrow.left:before {
  content: "\f104";
}

.component.flow .pagination.menu .item i.icon.arrow.right:before {
  content: "\f105";
}

.component.flow form.categories-list h3.ui.title {
  margin-top: 50px;
}

.component.flow .email-analysis-overview .tableWrapper {
  margin-bottom: 20px;
}

.component.flow .tableWrapper .ui.right.floated.menu {
  float: left;
}

.component.flow .email-analysis-overview .analysis-presets .hbox.flex-row>div.gapped {
  margin-bottom: 15px;
}

.component.flow .email-analysis-overview .analysis-presets .ui.link {
  padding: 13px 10px;
  border-radius: 0;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.58px;
  color: #000000;
}

.component.flow .email-analysis-overview .analysis-presets .ui.link:hover {
  color: #38A0FA;
}

.component.flow .email-analysis-overview .analysis-presets .ui.link.active {
  background-color: #16135F!important;
  border-color: #16135F!important;
  color: #FFFFFF;
}

.component.flow .email-analysis-overview .metric-card {
  border: 1px solid #E3EDED;
  border-radius: 3px;
  padding-top: 15px;
  padding-bottom: 30px;
  margin-right: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  color: #516C85;
}

.component.flow .email-analysis-overview .metric-card h3.ui.header {
  font-size: 36px;
  color: #16135F;
}

.component.flow .email-analysis-overview .metric-card .metrics-rate {
  color: #F5A623;
}

