
body {
  background: #FAFCFC;
}

.application > .container {
  width: 100%;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .meta.container {
    width: 100%;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .meta.container {
    width: 100%;
  }
}

/* Small Monitor */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .meta.container {
    width: 100%;
  }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) {
  .meta.container {
    width: 100%;
  }
}

.footer {
   color: rgba(0,0,0,0.4);
   padding-bottom: 5rem;
   padding-top: 1rem;
}

/* main header */
.main.header {
}

.header.main .avatar .hub a:link, .header.main .avatar .hub a:visited {
  color: white;
}

.container > .content {
  background: #FFFFFF;
  border: 1px solid #E3EDED;
  box-shadow: 0 5px 20px 0 #F6F9FE;
  border-radius: 3px;
  margin: 10px;
  min-height: 800px;
}

.application-loader {
  height: 100vh;
}

.assemblyEditor .stencil .joint-paper.joint-theme-default {
  background-color: #f9fafb;
}

.assemblyEditor .diagram .paper {
  width: 800px;
  height: 400px;
}

.flowEditor.component .metamodeler.component .joint-paper.joint-theme-default {
  background-color: rgba(22,19,95,0.04);
}

.flowEditor.component .metamodeler.component .joint-paper-scroller.joint-theme-default {
  border: 1px solid #979797;
  border-radius: 2px;
}

.flowEditor.component .metamodeler.component > .paper {
  width: 910px;
  height: 600px;
}

.flowEditor.component .stencil {
  background-color: #ffffff;
}

.flowEditor.component .stencil > .menu {
  margin-bottom: 0px;
  background-color: white;
}

.flowEditor.component .stencil .ui.accordion .title {
  color: #16135F!important;
  font-size: 14px!important;
  padding-left: 7px;
  letter-spacing: normal;
}

.flowEditor.component .inspector.component .ui.disabled.dropdown {
  opacity: 1;
}

/*
.topSpace {
  padding-top: 5rem;
}

.leftSpace {
  padding-left: 5rem;
}*/


.search.component  {
  padding-left: 1rem;
}

.search.component {
  background-color: #f9fafb;
  border-top-left-radius: .5rem;
  border-top-right-radius: 0.5rem;
}

.template-editor.component > .header {
  background-color: #f9fafb;
  border-top-left-radius: .5rem;
  border-top-right-radius: 0.5rem;
}

/* inspector */
.commhub .component.inspector .ui.form {
  font-size: 0.9rem;
}

.commhub .component.inspector h3.ui.header {
  font-size: 1.1rem;
}

.commhub .component.inspector h4.ui.dividing.header {
  color: #161360;
  font-size: 20px;
}


.commhub .component.inspector .ui.form label {
  font-size: 16px;
  color: #4A4A4A;
  letter-spacing: 0;
  font-weight: normal;
}

.commhub .component.inspector .ui.form input:not([type='file']) {
  border-radius: 2px;
  border: 1px solid #9B9B9B;
  font-size: 14px;
  color: #516C85;
  letter-spacing: 0;
}

.commhub .component.inspector .ui.form .ui.dropdown, .commhub .component.inspector .ui.form .ui.dropdown.active {
  border-radius: 2px;
  border: 1px solid #9B9B9B;
  font-size: 14px;
  color: #516C85;
  letter-spacing: 0;
}

.commhub .component.inspector .ui.form .ui.dropdown .menu.visible {
  border-radius: 2px;
  border: 1px solid #9B9B9B;
}

.flow.component .ui.grid>.row>.column.buttons-panel {
  margin-top: 2rem;
}

.flow.component {
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.flow.component .tableWrapper {
  overflow-x: auto;
}

.flow.component .no-table-scroll .tableWrapper {
  overflow-x: visible;
}

@media only screen and (max-width: 576px) {
  .flow.component .tableWrapper {
    overflow-x: visible;
  }
}


.meta.component.padded {
  padding: 1rem;
}


/* template editor - parts */
.template-editor .paper {
  background-color: white;
}

.template-editor .vspace {
  padding-top: 20px;
  padding-bottom: 20px;
}

.template-editor .hspace {
    padding-left: 50px;
    padding-right: 50px;
}

.template-editor .center {
  text-align: center;
}

.template-editor .template img {
   max-width: 100%;
}

.template-editor .button.moneta {
  background-color: rgb(54,47,88);
  color: white;
  padding: 5px 20px;
  width: max-content;
  font-weight: 600;
  font-size: 18px;
  display: flex;
}

.template-editor .button.nps-survay {
    background-color: rgb(54,47,88);
    color: white;
    padding: 5px 20px;
    width: max-content;
    font-weight: 600;
    font-size: 18px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
}

.template-editor .button.moneta  > span {
  flex-grow: 1;
  text-align: right;
  padding-left: 30px;
}

.template-editor .borderTop {
  border-top-color: gray;
  border-top-style: solid;
  border-top-width: 1px;
}

.template-editor .borderBottom {
  border-bottom-color: gray;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.template-editor .moneta.background {
  background: linear-gradient(to right, rgb(20,7,87) 25%, rgb(237, 28, 36) 85%);
}

.template-editor .flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.template-editor .no-wrap {
  white-space: nowrap;
}

.template-editor .mobile .button.nps-survay {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
}
.template-editor .horizontal-center {
  justify-content: center;
  display: flex;
}

.template-editor .moneta-footer {
  color: #767e88;
}


.template-editor .metlife .blue-color {
  color: rgb(21, 117, 181);
}

.template-editor .metlife .large-text {
  font-size: large;
  line-height: 30px;
}

.template-editor .metlife a {
  background: 0 0;
  color: #4183C4;
  text-decoration: none;
}


/* miniclient */
.ui.grid>.column:not(.row), .ui.grid>.row>.column.meta.left-padded {
  padding-left: 2rem;
}

/* template */
.panel-template {
  background-color: rgb(250, 250, 247);
  margin-left: 1rem;
  margin-right: 1rem;
}

.panel-template.selected {
  background-color: rgb(245, 245, 240);
}

.ui.form td .ui.grid {
  margin-right: auto;
}

.ui.list.flow-management .ui.segment.basic-element {
  min-width: 330px;
}

.ui.list.flow-management .ui.segment.time-constraints-element {
  min-width: 250px;
}

.ui.list.flow-management .ui.segment.flow-element {
  min-width: 450px;
}

/* reports */
.ch-metrics .field>label.help {
  display: none;
}

.ch-metrics h3.ui.header {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ch-metrics h5.ui.header {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ch-metrics .ui.wide.column.widget.segment {
  padding-top: 5px;
  padding-bottom: 5px;
}


/* calendar */

.form.calendar  .header .day {
  font-size: large;
  font-weight: normal;
}

.form.calendar .header  a {
  color: #16135F;
  cursor: pointer;
  font-weight: normal;
}

.form.calendar .header  a:hover {
  color: #4A90E2;
}
