/* Paper */

.metamodeler .joint-theme-default.joint-paper-scroller {
  flex-grow: 1;
}



/* Context menu*/

.joint-context-menu {
    position: absolute;
    z-index: 1000;
}

.joint-cell-dialog {
    position: absolute;
    z-index: 1000;
    width: 350px;
}

/* Overlay screen */

.overlay {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 3; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    /*overflow: hidden;*/ /* Enable scroll if needed */
    /*background-color: rgb(0,0,0);*/ /* Fallback color */
    /*background-color: rgba(0,0,0,0.4);*/ /* Black w/ opacity */
}

.joint-halo.joint-theme-default .handle:before{
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
}

/*.joint-halo.joint-theme-default .handle.link:before{*/
  /*content: "\f105";*/
/*}*/

.joint-halo.joint-theme-default .handle. {
  background-color: white;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  padding-top: 5px;
  padding-left: 7px;
  margin-left: -15px;
  bottom: -35px;
}
.joint-halo.joint-theme-default .handle.link:before {
  content: "\f14c"; /*'\2B24';*/
  font-size: 13pt;
}

@media (max-width: 800px) {
  .joint-halo.joint-theme-default .handle:before {
    font-size: 25pt;
  }
  .joint-halo.joint-theme-default .handle {
    width: 50px;
    height: 50px;
    margin-left: -25px;
    padding-top: 18px;
    padding-left: 12px;
    bottom: -55px;
  }
}



.joint-halo.joint-theme-default .handles .handle {
 background-image: none;
}

.joint-halo.joint-theme-default .handle.remove:before{
  content: "\f00d";
}

.joint-halo.joint-theme-default .handle.remove {
  background-image: none;
}

.joint-halo.joint-theme-default .handle.clone:before{
  content: "\f24d";
}

.joint-halo.joint-theme-default .handle.clone {
  background-image: none;
}

.joint-halo.joint-theme-default .handle.resize:before{
  content: "\f0b2";
}

.joint-halo.joint-theme-default .handle.resize {
  background-image: none;
}

.joint-halo.joint-theme-default .handle.explore:before{
  content: "\f06e";
}

.link-tools {
  display: none;
}

.marker-vertex-remove, .marker-vertex-remove-area {
  display: none;
}

.selection-wrapper { display: none; }
