/* desktop display */
.template-editor {
  padding: 10px 20px;
}

.template-editor .template {
  border-top: 1px solid #979797;
  margin-top: 5px;
}

.template-editor .template.desktop {
    background-color: rgb(233,236,237);
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.template-editor .template.desktop .contentPlaceholder {
  display: flex;
  padding-left: 20px;
}



.template-editor .template.desktop .contentPlaceholder .paper {
     width: 800px;
}

/* tablet display */
.template-editor .template.tablet {
    background-color: rgb(233,236,237);
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.template-editor .template.tablet .contentPlaceholder {
  display: flex;
  padding-left: 20px;
}


.template-editor .template.tablet .contentPlaceholder .paper {
     width: 600px;
}

/* mobile display */
.template-editor .template.mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.template-editor .template.mobile .inner {
    overflow: hidden;
    /*background-color: white;*/
    background-image: url(./icons/smartphone.png);
    background-size: 465px;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    width: 465px;
    height: 1000px;
}


.template-editor .template.mobile .inner > .mobile {
  margin-top: 120px;
  margin-left: 39px;
  width: 363px;
  height: 646px;

  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;


}

.template-editor a {
  color: rgb(0, 0, 238);
  text-decoration: underline;
}

.template-editor .button a {
  color: white;
  text-decoration: none;
}

.template-editor .template.mobile .contentPlaceholder {
  display: flex;
}

.template-editor .template.mobile .contentPlaceholder .paper {
  width: 100%;
}



/*.template-editor .template.mobile .contentPlaceholder .right.toolbar {
  position: absolute;
  right: 0px;
  top: 0px;
}*/


.template-editor > .component > .meta.menu > .item.data-structure .ui.selection.search.fluid.dropdown {
  width: 20em;
}

.template-editor .meta.menu.top.component {
  border-bottom: none;
}

.template-editor .meta.menu.top.component .item.savedelete {
  margin-left: 20px;
}

.template-editor .meta.menu.top.component .menu-item {
  padding: 5px 5px;
}

.template-editor .meta.menu.top.component .menu-item.display {
  margin-right: 20px;
}

.template-editor .meta.menu.top.component .menu-item:not(:first-child) .ui.button.divider-button  {
  border-left: 1px solid #D8D8D8;
  border-radius: 0;
}

.template-editor .meta.menu.top.component .ui.button.flat {
  background-color: transparent;
  color: #16135F;
  font-weight: normal;
}

.template-editor .meta.menu.top.component .ui.button.flat:hover {
  color: #4A90E2;
}

.template-editor .meta.menu.top.component .ui.button.flat.display {
  padding-left: 2px;
  padding-right: 2px;
}

.template-editor .meta.menu.top.component .ui.button.flat.active-display {
  color: #30CB81;
}

.template-editor .meta.menu.top.component .ui.button.flat.text {
  color: #000000;
  cursor: default;
}

.template-editor .meta.menu.top.component .menu-item.display .ui.button.flat.text {
  padding-right: 5px;
}

.template-editor .meta.menu.top.component .ui.button.flat.text:hover {
  color: #000000;
}

.template-editor .meta.menu.top.component .ui.labeled.icon.button {
  padding-right: 15px!important;
  padding-left: 30px!important;;
}

.template-editor .meta.menu.top.component .ui.labeled.icon.button>.icon {
  background-color: transparent;
}

.template-editor .meta.menu.top.component button.save {
  background: #30CB81;
  border-radius: 2px;
  border: none;
  color: #FFFFFF;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: normal;
  padding-left: 30px;
  padding-right: 30px;
}

.template-editor .meta.menu.top.component button.save:hover {
  background: #289c63;
}

.template-editor .meta.menu.top.component button.delete {
  background: transparent;
  border-radius: 2px;
  color: #D0021B;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: normal;
  border: 1px solid #D0021B;
  padding: 11px;
  padding: 11px;
}

.template-editor .meta.menu.top.component button.delete:hover {
  background: #fae6e8;
}

.template-editor input:not([type='file']) {
  border-radius: 2px;
  border: 1px solid #9B9B9B;
  font-size: 14px;
  color: #516C85;
  letter-spacing: 0;
}

.template-editor .ui.dropdown, .component.flow .ui.dropdown.active {
  border-radius: 2px;
  border: 1px solid #9B9B9B;
  font-size: 14px;
  color: #516C85;
  letter-spacing: 0;
}

.template-editor .ui.dropdown .menu.visible {
  border-radius: 2px;
  border: 1px solid #9B9B9B;
}