
.header.main > div > .top {
  background: #FFFFFF;
  box-shadow: 0 4px 11px 0 rgba(147, 168, 168, 0.46);
}

.header.main > div > .top > .first {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.16);
  padding: 10px;
}

.header.main .logo {
  padding-left: 30px;
}

.header.main .user {
  padding-right: 30px;
}

.header.main .user .text {
  font-size: 14px;
  color: #16135F;
  letter-spacing: 0;
}


.header.main .console-buttons {
  padding-left: 80px;
}


.header.main .console-buttons > .button:first-child {
  padding-right: 30px;
}

.header.main .console-buttons > .button {
  cursor: pointer;
}

.header.main .console-buttons > .button > img {
  padding-left: 5px;
  padding-right: 5px;
}

.header.main .console-buttons > .button > span {
  padding-button: 3px;
}

.header.main .console-buttons .button.active {
  color: #D0021B;
}

.header.main .console-buttons .button.pasive {
  color: #16135F;
}

.header.main > .column > .bottom {
}

.header.main .button {
  /*box-shadow: 0 0 0 2px #fff inset!important;
  background: transparent none;
  color: #fff;
  text-shadow: none!important;*/
}
/* breadcrumb */
.header.main .menu .ui.breadcrumb .divider {
  padding-left: 10px;
  padding-right: 10px;
}

.header.main .menu .ui.breadcrumb .section {
  cursor: pointer;
}

.header.main .menu .ui.breadcrumb .ui.buttons.version {
  margin-left: 10px;
}

.header.main .version > .text {
  cursor: pointer;
  font-size: 14px;
  color: #4A4A4A;
  letter-spacing: 0.58px;
  padding-right: 10px;
}

.header.main .version > .dropdown {
  font-size: 14px;
  color: #161360;
  letter-spacing: 0.58px;
}

.header.main .version > .ui.inline.dropdown>.text {
  font-weight: normal;  
}


.header.main .breadcrumb {
  font-size: 13px;
  color: #4A90E2;
  letter-spacing: 0;
}

.header.main .breadcrumb {
  font-size: 13px;
  color: #4A90E2;
  letter-spacing: 0;
}

.header.main .ui.breadcrumb .active.section {
  color: #16135F;
  font-weight: normal;
}


.header.main .bottom > .left {
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 10px;
}


/* menu */
.meta.menu {
  align-items: stretch;
}

.meta.menu > .item {
  display: flex;
  align-items: center;
  padding-right: 0.2rem;
}


.meta.menu > .item:first-child {
  padding-left: 0.2rem;
}

/* navigation bar */

.header.main .navigation-bar {
  padding-left: 300px;
}

.header.main .navigation-bar > .item {
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  cursor: pointer;
  padding: .78571429rem 1.14285714rem;
  display: flex;
  align-items: center;
}

.header.main .navigation-bar > .item.active, .header.main .navigation-bar .navigation-bar-dropdown-item.active > .text  {
  color: #D0021B;
}

/* title */

.header.main .title.main {
  font-size: 36px;
  color: #16135F;
  letter-spacing: 2px;
  font-weight: 300;
}


/* console */
.header.main > .console {
    color: white;
    padding: 0 0.5rem;
}

.header.main.design > .console {
    background-color: rgb(208, 155, 152);
}

.header.main.runtime > .console {
    background-color: rgb(118, 161, 123);
}
