.joint-halo.joint-theme-default .handle.answerHandler::before {
    content: "\f14c";
    font-size: 13pt;
    color: red;
}

.component.flowEditor .meta.menu.top.component .menu-item {
  padding: 5px 5px;
}

.component.flowEditor .meta.menu.top.component .menu-item .ui.button.divider-button {
  border-left: 1px solid #D8D8D8;
  border-radius: 0;
}

.component.flowEditor .meta.menu.top.component .ui.button.flat {
  background-color: transparent;
  color: #16135F;
}

.component.flowEditor .meta.menu.top.component .ui.button.flat:hover {
  color: #4A90E2;
}

.component.flowEditor .meta.menu.top.component button.save {
  background: #30CB81;
  border-radius: 2px;
  border: none;
  color: #FFFFFF;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: normal;
  padding-left: 30px;
  padding-right: 30px;
}

.component.flowEditor .meta.menu.top.component button.save:hover {
  background: #289c63;
}

.component.flowEditor .meta.menu.top.component button.delete {
  background: transparent;
  border-radius: 2px;
  color: #D0021B;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: normal;
  border: 1px solid #D0021B;
  padding: 11px;
  padding: 11px;
}

.component.flowEditor .meta.menu.top.component button.delete:hover {
  background: #fae6e8;
}