/* Stencil */
.stencil.component .joint-theme-default.joint-stencil {
  border: none;
   
}

.metamodeler .stencil {
  width: 180px;
  background-color: rgb(250,251,252);
}

.stencil.component .inner {
  position: relative;
}

.stencil.component .inner > .joint-stencil > .content {
  overflow: hidden;
}

.stencil.component .joint-paper > svg, 
.stencil.component .joint-paper-background,
.stencil.component .joint-paper-grid {
  padding-left: 7px;
}
