.meta.navigation-bar-dropdown-item > .content {
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 2;
    background-color: white;
}

.meta.navigation-bar-dropdown-item > .text {
  cursor: default;
  padding-bottom: 14px;
  padding-top: 15px;
  padding-left: .78571429rem;
  padding-right: .78571429rem;
}

.meta.navigation-bar-dropdown-item > .content > .item {
  cursor: pointer;
  padding: .78571429rem 1.14285714rem;
  white-space: nowrap;
}

.meta.navigation-bar-dropdown-item > .content > .item:hover {
  background: rgba(0,0,0,.05);
}
